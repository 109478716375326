<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <PermissionsSelection :permissionsFilter="permissionsFilter"
                                    :permissionsIdsFilter="permissionsIdsFilter"/>
              <v-dialog persistent v-model="deleteDialog" max-width="900">
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="deleteDialog = false; itemToDelete = {}; assigneeId = '';"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span>Assign {{ itemToDelete.name }}'s ({{itemToDelete.email}}) working to another User</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!assigneeId"
                          dark
                          text
                          @click="deleteItem(itemToDelete, assigneeId)"
                      >
                        Assign and Delete User
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-autocomplete
                              v-model="assigneeId"
                              :items="staffFilter"
                              item-text="name"
                              item-value="id"
                              hide-details
                              clearable
                              outlined
                              dense
                              deletable-chips
                              chips
                              small-chips
                              label="Select User"
                          >
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-title
                                    v-html="data.item.name + ' (' + data.item.email + ')'">
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="dialog" fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn color="#00004d" dark class="ml-auto ma-3 mr-1" @click="refreshData(true)">
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn v-if="hasPermission('users.create')" color="#00004d" dark class="ma-3 ml-0" v-on="on">
                      Add
                      <v-icon class="ml-2" small>fas fa-plus-circle</v-icon>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="closeDialog"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span v-if="editedItem.id">Edit "{{ editedItem.email }}"</span>
                      <span v-else>Create User</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!validationRules.valid"
                          dark
                          text
                          @click="saveItem(editedItem)"
                      >
                        Save
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-tabs v-model="activeTab" background-color="#002366" left dark>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab>Basic Details</v-tab>
                    <v-tab>User Details</v-tab>
                    <v-tab>Bank Details</v-tab>
                    <v-tab>Card Details</v-tab>
                    <v-tab>Social Media Handler</v-tab>
                    <v-tab>Media</v-tab>
                    <v-tab v-if="$store.state.auth.user.type === 1">Marketing</v-tab>
                    <v-tab>Comments</v-tab>

                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-form
                              class="custom_form"
                              ref="form"
                              v-model="validationRules.valid"
                              lazy-validation
                          >
                            <v-row>
                              <v-col cols="12" sm="4">
                                <v-select clearable
                                          outlined
                                          dense v-model="editedItem.type_of_user" :items="typeOfUser"
                                          item-text="title"
                                          item-value="value"
                                          :rules="validationRules.typeOfUserRules"
                                          @change="typeOfUserChange"
                                          label="Type of User*">
                                </v-select>
                              </v-col>
                              <v-col cols="12" sm="4" v-if="userTypeIsInd">
                                <v-autocomplete
                                    v-model="editedItem.salutation_id"
                                    :items="salutationFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                    deletable-chips
                                    label="Salutation"
                                    :append-icon="'mdi-refresh'"
                                    @click:append="getSalutations(false)"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/users-management/salutations?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title
                                            v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4" v-if="nameLabel">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.name" :counter="300"
                                              :rules="validationRules.firstNameRules"
                                              :label="nameLabel + '*'"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" v-if="userTypeIsInd">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.father_husband_name" :counter="300"
                                              label="Father/Husband/Guardian Name"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" v-if="!editedItem.is_edit">
                                <v-text-field v-model="editedItem.email" :counter="100"
                                              clearable
                                              outlined
                                              dense
                                              :rules="validationRules.emailRules" label="Email*"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field v-model="editedItem.password" :counter="20"
                                              clearable
                                              outlined
                                              dense
                                              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                              :type="show ? 'text' : 'password'"
                                              @click:append="show = !show" label="Password"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" v-if="userTypeIsInd">
                                <v-text-field type="date" clearable
                                              outlined
                                              dense
                                              v-model="editedItem.dob"
                                              label="Date Of Birth"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" v-if="userTypeIsInd">
                                <v-select clearable
                                          outlined
                                          dense v-model="editedItem.gender" :items="genders"
                                          item-text="title"
                                          item-value="value"
                                          label="Gender" required>
                                </v-select>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.source_id"
                                    :items="sourcesFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                    deletable-chips
                                    label="Source"
                                    :append-icon="'mdi-refresh'"
                                    @click:append="getSources(false)"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/setup/sources?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title
                                            v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4"
                                     v-if="editedItem.name && editedItem.email && editedItem.is_primary !== 1">
                                <v-btn
                                    outlined
                                    dense
                                    color="#00004d"
                                    dark
                                    height="40"
                                    @click="$store.state.selections.permissionsDialog = true"
                                    style="width:100%"
                                >
                                  <span v-if="$store.state.selections.selectedPermissionIds.length > 0"
                                        v-html="$store.state.selections.selectedPermissionIds.length + ' Permissions Selected'"
                                  ></span>
                                  <span v-else>Select Permissions</span>
                                </v-btn>
                              </v-col>
                              <v-col cols="12" sm="4"
                                     v-if="editedItem.name && editedItem.email && editedItem.is_primary !== 1 && $store.state.auth.user.is_primary === 1">
                                <v-select clearable
                                          outlined
                                          dense v-model="editedItem.is_sub_admin" :items="questions"
                                          item-text="title"
                                          item-value="value"
                                          label="Make Sub Administrator" required>
                                </v-select>
                              </v-col>
                            </v-row>
                            <v-row v-if="editedItem.type_of_user === '1'">
                              <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="editedItem.tln_authority_id"
                                    :items="tlnAuthorityFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isTlnAuthorityLoaded"
                                    small-chips
                                    label="Trade License Number Issuing Authority (min. 3 characters required)"
                                    @keyup="tlnAuthorityFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field v-model="editedItem.trade_license_number"
                                              clearable
                                              outlined
                                              dense
                                              :counter="50"
                                              label="Trade Licence Number"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row v-if="editedItem.type_of_user === '1'">
                              <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="editedItem.orn_authority_id"
                                    :items="ornAuthorityFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isOrnAuthorityLoaded"
                                    small-chips
                                    label="Organization Registration Number Issuing Authority (min. 3 characters required)"
                                    @keyup="ornAuthorityFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field v-model="editedItem.organization_registration_number"
                                              clearable
                                              outlined
                                              dense
                                              :counter="50"
                                              label="Organization Registration Number"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row v-if="editedItem.type_of_user">
                              <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="editedItem.brn_authority_id"
                                    :items="brnAuthorityFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isBrnAuthorityLoaded"
                                    small-chips
                                    label="Broker Registration Number Issuing Authority (min. 3 characters required)"
                                    @keyup="brnAuthorityFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field v-model="editedItem.broker_registration_number"
                                              clearable
                                              outlined
                                              dense
                                              :counter="50"
                                              label="Broker Registration Number"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row v-if="editedItem.type_of_user === '1'">
                              <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="editedItem.drn_authority_id"
                                    :items="drnAuthorityFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isDrnAuthorityLoaded"
                                    small-chips
                                    label="Developer Registration Number Issuing Authority (min. 3 characters required)"
                                    @keyup="drnAuthorityFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field v-model="editedItem.developer_registration_number"
                                              clearable
                                              outlined
                                              dense
                                              :counter="50"
                                              label="Developer Registration Number"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <v-textarea outlined
                                            dense clearable
                                            v-model="editedItem.notes" :counter="500"
                                            label="Notes">

                                </v-textarea>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" v-if="editedItem.is_primary === 1">
                              <v-alert
                                  dense
                                  type="info"
                              >
                                Emails, Phone numbers and Website links will be used in reports header.
                              </v-alert>
                            </v-col>
                            <UserDetails
                                moduleOf="user_details"
                                moduleTitle="Email"
                                moduleLabel="Emails"
                                moduleFor="user_emails"
                                :userDetailsOld="editedItem.user_emails ? editedItem.user_emails : editedItem.user_emails === []"
                            ></UserDetails>
                            <UserDetails
                                moduleOf="user_details"
                                moduleTitle="Phone"
                                moduleLabel="Phones"
                                moduleFor="user_phones"
                                :userDetailsOld="editedItem.user_phones ? editedItem.user_phones : editedItem.user_phones === []"
                            ></UserDetails>
                            <UserDetails
                                moduleOf="user_details"
                                moduleTitle="Address"
                                moduleLabel="Addresses"
                                moduleFor="user_addresses"
                                :userDetailsOld="editedItem.user_addresses ? editedItem.user_addresses : editedItem.user_addresses === []"
                            ></UserDetails>
                            <UserDetails
                                moduleOf="user_details"
                                moduleTitle="Passport Number"
                                moduleLabel="Passport Number"
                                moduleFor="user_passport_numbers"
                                :userDetailsOld="editedItem.user_passport_numbers ? editedItem.user_passport_numbers : editedItem.user_passport_numbers === []"
                            ></UserDetails>
                            <UserDetails
                                moduleOf="user_details"
                                moduleTitle="National/Resident's Identification"
                                moduleLabel="National/Resident's Identification"
                                moduleFor="user_national_ids"
                                :userDetailsOld="editedItem.user_national_ids ? editedItem.user_national_ids : editedItem.user_national_ids === []"
                            ></UserDetails>
                            <UserDetails
                                moduleOf="user_details"
                                moduleTitle="Event"
                                moduleLabel="Events"
                                moduleFor="user_events"
                                :userDetailsOld="editedItem.user_events ? editedItem.user_events : editedItem.user_events === []"
                            ></UserDetails>
                            <UserDetails
                                moduleOf="user_details"
                                moduleTitle="Website"
                                moduleLabel="Websites"
                                moduleFor="user_websites"
                                :userDetailsOld="editedItem.user_websites ? editedItem.user_websites : editedItem.user_websites === []"
                            ></UserDetails>
                            <UserDetails
                                moduleOf="user_details"
                                moduleTitle="Relationship"
                                moduleLabel="Relationships"
                                moduleFor="user_relationships"
                                :userDetailsOld="editedItem.user_relationships ? editedItem.user_relationships : editedItem.user_relationships === []"
                            ></UserDetails>
                            <UserDetails
                                moduleOf="user_details"
                                moduleTitle="Chat"
                                moduleLabel="Chats"
                                moduleFor="user_chats"
                                :userDetailsOld="editedItem.user_chats ? editedItem.user_chats : editedItem.user_chats === []"
                            ></UserDetails>
                            <UserDetails
                                moduleOf="user_details"
                                moduleTitle="Internet Call"
                                moduleLabel="Internet Calls"
                                moduleFor="user_internet_calls"
                                :userDetailsOld="editedItem.user_internet_calls ? editedItem.user_internet_calls : editedItem.user_internet_calls === []"
                            ></UserDetails>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <UserBankDetails
                                moduleOf="user_bank_details"
                                moduleTitle="Bank Details"
                                :userBankDetailsOld="editedItem.user_bank_details ? editedItem.user_bank_details : editedItem.user_bank_details === []"
                            ></UserBankDetails>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <UserCardDetails
                                moduleOf="user_card_details"
                                moduleTitle="Card Details"
                                :userCardDetailsOld="editedItem.user_card_details ? editedItem.user_card_details :editedItem.user_card_details ===  []"
                            ></UserCardDetails>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <ModuleLinks
                                moduleOf="user_links"
                                moduleFor="youtube_videos_links"
                                moduleTitle="Youtube Videos"
                                :moduleLinksOld="editedItem.youtube_videos_links ? editedItem.youtube_videos_links :editedItem.youtube_videos_links ===  []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="user_links"
                                moduleFor="youtube_playlist_links"
                                moduleTitle="Youtube Playlist"
                                :moduleLinksOld="editedItem.youtube_playlist_links ? editedItem.youtube_playlist_links : editedItem.youtube_playlist_links === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="user_links"
                                moduleFor="virtual_tour_links"
                                moduleTitle="Virtual Tour"
                                :moduleLinksOld="editedItem.virtual_tour_links ? editedItem.virtual_tour_links : editedItem.virtual_tour_links === []"
                            ></ModuleLinks>
                            <hr>
                            <ModuleLinks
                                moduleOf="user_links"
                                moduleFor="instagram_urls"
                                moduleTitle="Instagram URLS"
                                :moduleLinksOld="editedItem.instagram_urls ? editedItem.instagram_urls : editedItem.instagram_urls === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="user_links"
                                moduleFor="facebook_urls"
                                moduleTitle="Facebook URLS"
                                :moduleLinksOld="editedItem.facebook_urls ? editedItem.facebook_urls : editedItem.facebook_urls === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="user_links"
                                moduleFor="twitter_urls"
                                moduleTitle="Twitter URLS"
                                :moduleLinksOld="editedItem.twitter_urls ? editedItem.twitter_urls : editedItem.twitter_urls === []"
                            ></ModuleLinks>
                            <hr>
                            <ModuleLinks
                                moduleOf="user_links"
                                moduleFor="linkedin_urls"
                                moduleTitle="Linkedin URLS"
                                :moduleLinksOld="editedItem.linkedin_urls ? editedItem.linkedin_urls : editedItem.linkedin_urls === []"
                            ></ModuleLinks>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-tabs
                          background-color="primary" left dark>
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab>Logo/Photo</v-tab>
                        <v-tab>Gallery</v-tab>
                        <v-tab>Private Documents</v-tab>

                        <v-tab-item>
                          <v-col cols="12" v-if="editedItem.is_primary === 1">
                            <v-alert
                                dense
                                type="info"
                                class="mt-5"
                            >
                              This Logo will be used in reports header.
                            </v-alert>
                          </v-col>
                          <ModuleMedia
                              moduleType="main"
                              moduleOf="users"
                              moduleTitle="Logo/Photo"
                              acceptedFiles="image/jpeg, image/png"
                              :moduleMultiple="false"
                              :deleteModule="false"
                              :moduleFilesOld="editedItem.image_public_url ? [editedItem] : []"
                          ></ModuleMedia>
                        </v-tab-item>
                        <v-tab-item>
                          <ModuleMedia
                              moduleType="other"
                              moduleOf="user_files"
                              moduleFor="user_gallery"
                              moduleTitle="Gallery"
                              acceptedFiles="image/jpeg, image/png"
                              :moduleMultiple="true"
                              :deleteModule="true"
                              :moduleFilesOld="[]"
                              moduleOfFolder="users"
                          ></ModuleMedia>
                          <ModuleUploadedFiles
                              v-if="showUploadedFiles"
                              :moduleItem="editedItem"
                              moduleOf="user_files"
                              moduleKey="user_id"
                              moduleTitle="Gallery"
                              moduleOfFolder="users"
                          ></ModuleUploadedFiles>
                        </v-tab-item>
                        <v-tab-item>
                          <ModuleMedia
                              moduleType="other"
                              moduleOf="user_files"
                              moduleFor="private_documents"
                              moduleTitle="Private Documents"
                              acceptedFiles="image/jpeg, image/png, application/pdf"
                              :moduleMultiple="true"
                              :deleteModule="true"
                              :moduleFilesOld="[]"
                              moduleOfFolder="users"
                          ></ModuleMedia>
                          <ModuleUploadedFiles
                              v-if="showUploadedFiles"
                              :moduleItem="editedItem"
                              moduleOf="user_files"
                              moduleKey="user_id"
                              moduleTitle="Private Documents"
                              moduleOfFolder="users"
                          ></ModuleUploadedFiles>
                        </v-tab-item>
                      </v-tabs>
                    </v-tab-item>
                    <v-tab-item v-if="$store.state.auth.user.type === 1">
                      <v-card flat>
                        <v-card-text>
                          <v-form>
                            <v-row>
                              <v-col cols="12" sm="3">
                                <v-text-field
                                    clearable
                                    outlined
                                    :counter="3"
                                    maxLength="3"
                                    dense
                                    v-model="editedItem.sms_quota"
                                    @keypress="isNumber($event)"
                                    @paste="onPaste($event)"
                                    label="SMS Marketing Quota"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <UserComments
                                moduleOf="user_comments"
                                moduleTitle="Comments"
                                :userCommentsOld="editedItem.user_comments ? editedItem.user_comments : editedItem.user_comments === []"
                            ></UserComments>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn :disabled="!validationRules.valid" color="blue darken-1" text @click="saveItem(editedItem)">
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <hr style="margin:0"/>
              <div class="col-md-12">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
                      <div class="text-center font-weight-bold">
                        Search/Filters
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-form v-on:submit.prevent="hitAdvanceSearch(false)">
                        <v-row>
                          <v-col cols="12" sm="4">
                            <v-select
                                clearable
                                dense
                                outlined
                                hide-details
                                v-model="searchFormData.typeOfUserSearch"
                                :items="typeOfUser"
                                item-text="title"
                                item-value="value"
                                label="Type of User">
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field :counter="300"
                                          outlined
                                          dense
                                          hide-details
                                          maxlength="300" v-model="searchFormData.fullNameSearch"
                                          label="Name"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field :counter="100"
                                          outlined
                                          dense
                                          hide-details
                                          maxlength="100" v-model="searchFormData.emailSearch"
                                          label="Email"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <button :disabled="loading" type="submit" style="width: 100%"
                                    class="btn btn-success theme-btn ml-auto mr-2">
                              Search
                              <v-icon class="ml-2" small>fas fa-search</v-icon>
                            </button>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <button :disabled="loading" style="width: 100%" @click="hitAdvanceSearch(true)"
                                    class="btn btn-primary ml-auto">
                              Reset
                              <v-icon class="ml-2" small>fas fa-times</v-icon>
                            </button>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <v-card-title>
                {{ $store.state.auth.vendor }}'s Users ({{ addTSBVZ(totalRecords) }})
              </v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-pagination
                      color="#00004d"
                      :total-visible="15"
                      @input="refreshData(false)"
                      :disabled="loading"
                      v-model="pagination.page"
                      :length="pageCount">
                  </v-pagination>
                </v-col>
              </v-row>
              <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
              >
              </v-skeleton-loader>
              <v-card-text v-if="!isLoaded">
                <v-row v-if="items.length > 0">
                  <v-col cols="12" v-for="(item, index) in items" :key="index">
                    <v-card
                        class="mx-auto"
                        outlined
                        raised
                    >
                      <v-toolbar
                          height="40"
                          dark
                          color="#00004d"
                      >
                        <v-toolbar-title>
                          <span v-if="item.is_primary === 0" v-html="$store.state.auth.vendor"></span>
                          <v-icon v-if="item.is_primary === 0" dark x-small class="ml-1 mr-1">fas
                            fa-angle-double-right
                          </v-icon>
                          <span
                              v-if="item.type_of_user && item.type_of_user === '0'"
                              v-html="item.salutation ? item.salutation.title : ''">
                          </span> <span v-html="item.name"></span>
                          <v-icon dark x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>
                          <span v-html="item.email"></span>
                          <span v-if="item.is_primary === 1"> (Primary)</span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                          <v-btn
                              v-if="
                              (hasPermission('users.update') &&
                              $store.state.auth.user.is_primary === 1) ||
                              (hasPermission('users.update') &&
                              $store.state.auth.user.is_sub_admin === 1) ||
                              (hasPermission('users.update') && item.id === $store.state.auth.user.id) ||
                              (hasPermission('users.update') && item.parent_id === $store.state.auth.user.id)
                              "
                              dark
                              text
                              @click="showEditDialog(item)"
                          >
                            Edit
                          </v-btn>
                          <v-btn
                              :disabled="staffFilter.length === 0"
                              v-if="
                              (hasPermission('users.archive') &&
                              $store.state.auth.user.is_primary === 1 && item.is_primary !== 1) ||
                              (hasPermission('users.archive') &&
                              $store.state.auth.user.is_sub_admin === 1 && item.is_primary !== 1)
                              "
                              @click="showDeleteDialog(item)"
                              dark
                              text
                          >
                            Archive
                          </v-btn>
                        </v-toolbar-items>
                      </v-toolbar>
                      <v-card-text>
                        <v-row>
                          <!--                          <v-col cols="12" sm="3">-->
                          <!--                            <v-img :lazy-src="getImagePlaceHolder()" :src="getPicture(item)"-->
                          <!--                                   class="listing_gallery_image"></v-img>-->
                          <!--                          </v-col>-->
                          <v-col cols="12" sm="4">
                            <div>
                              <h6>Type</h6>
                              <p v-if="item.type_of_user === '0'">Individual</p>
                              <p v-else-if="item.type_of_user === '1'">Institution/Company/Organization</p>
                              <p v-else>-</p>
                            </div>
                            <div>
                              <h6>Name</h6>
                              <p v-html="item.name"></p>
                            </div>
                            <div>
                              <h6>Email</h6>
                              <p v-html="item.email"></p>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <div>
                              <h6>Verified</h6>
                              <p v-if="item.is_verified === 0">No</p>
                              <p v-else-if="item.is_verified === 1">Yes</p>
                              <p v-else>-</p>
                            </div>
                            <div v-if="item.parent">
                              <h6>Added By:</h6>
                              <p v-html="item.parent.name + ' (' + item.parent.email + ')'"></p>
                            </div>
<!--                            <div v-if="item.is_primary === 1 || item.is_sub_admin === 1">-->
<!--                              <h6>Added By:</h6>-->
<!--                              <p v-html="$store.state.auth.vendor + ' (' + item.email + ')'"></p>-->
<!--                            </div>-->
                            <div v-if="item.type_of_user === '0'">
                              <h6>Gender</h6>
                              <p v-if="item.gender === '0'">Male</p>
                              <p v-else-if="item.gender === '1'">Female</p>
                              <p v-else>-</p>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <!--                            <div>-->
                            <!--                              <h6>Last Login</h6>-->
                            <!--                              <p>{{ item.last_login ? formatDateAndTime(item.last_login) : '-' }}</p>-->
                            <!--                            </div>-->
                            <div>
                              <h6>SMS Marketing Quota</h6>
                              <p>{{ item.used_sms_quota }} / {{ item.sms_quota }}</p>
                            </div>
                            <div>
                              <h6>Source</h6>
                              <p v-html="item.source ? item.source.title : '-'"></p>
                            </div>
                            <div v-if="$store.state.auth.user.is_primary === 1 && item.is_primary !== 1">
                              <h6>Sub Administrator</h6>
                              <v-switch
                                  v-model="item.is_sub_admin"
                                  v-bind:color="item.is_sub_admin === 1 ? 'success': ''"
                                  item-value="value"
                                  @change="updateStatus($event,item.id,'is_sub_admin')"
                              ></v-switch>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <!--                      <v-toolbar-->
                      <!--                          height="30"-->
                      <!--                          dark-->
                      <!--                          color="primary"-->
                      <!--                      >-->
                      <!--                        <v-toolbar-title>-->
                      <!--                          Created at:-->
                      <!--                          {{ formatDateAndTime(item.created_at) }} by-->
                      <!--                          {{ item.created_user_name }}-->
                      <!--                        </v-toolbar-title>-->
                      <!--                        <v-spacer></v-spacer>-->
                      <!--                        <v-toolbar-title>-->
                      <!--                          Updated at:-->
                      <!--                          {{ formatDateAndTime(item.updated_at) }} by-->
                      <!--                          {{ item.updated_user_name }}-->
                      <!--                        </v-toolbar-title>-->
                      <!--                      </v-toolbar>-->
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">
                    <p class="text-center"><i>No Record Found.</i></p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  @update:items-per-page="refreshData(false)"
                  :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true
                }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="100"
                  class="elevation-0 custom-datatable"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-data-footer
                      class="custom-datatable-footer"
                      :pagination="pagination"
                      :options="options"
                      @update:options="updateOptions"
                      :items-per-page-options="rowsPerPageItems"
                      :show-first-last-page="true"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
                </template>
              </v-data-table>
              <v-row>
                <v-col cols="12">
                  <v-pagination
                      color="#00004d"
                      :total-visible="15"
                      @input="refreshData(false)"
                      :disabled="loading"
                      v-model="pagination.page"
                      :length="pageCount">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import PermissionsSelection from "@/view/pages/realstate/selections/PermissionsSelection";
import ModuleMedia from "@/view/pages/realstate/media/ModuleMedia";
import ModuleLinks from "@/view/pages/realstate/media/ModuleLinks";
import UserDetails from "@/view/pages/realstate/users/inc/UserDetails";
import UserBankDetails from "@/view/pages/realstate/users/inc/UserBankDetails";
import UserCardDetails from "@/view/pages/realstate/users/inc/UserCardDetails";
import UserComments from "@/view/pages/realstate/users/inc/UserComments";
import ModuleUploadedFiles from "@/view/pages/realstate/media/ModuleUploadedFiles";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  USERS_LIST,
  CREATE_USER,
  UPDATE_USER,
  CLEAR_USER_ERRORS,
  DELETE_USER, GET_USER_DETAILS, UPDATE_USER_STATUS, STAFF_LIST
} from "@/core/services/store/users.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {bus} from "@/main";
import {SOURCES_SIMPLE_LIST} from "@/core/services/store/sources.module";
import {SALUTATIONS_SIMPLE_LIST} from "@/core/services/store/salutations.module";
import {PERMISSIONS_ADMIN_LIST, PERMISSIONS_VENDOR_LIST} from "@/core/services/store/permissions.module";
import {CONTACTS_SIMPLE_LIST} from "@/core/services/store/contacts.module";

export default {
  name: 'users',
  components: {
    ModuleMedia,
    ModuleLinks,
    UserDetails,
    UserBankDetails,
    UserCardDetails,
    UserComments,
    PermissionsSelection,
    ModuleUploadedFiles
  },
  data() {
    return {
      draw: 1,
      searchQuery: "",
      loading: true,
      isLoaded: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      showUploadedFiles: false,
      headers: [
        // {
        //   text: 'Photo/Logo',
        //   value: 'image_public_url',
        //   data: 'image_public_url',
        //   name: 'image',
        //   filterable: false,
        //   sortable: false
        // },
        {
          text: 'Type of User',
          value: 'type_of_user',
          name: 'type_of_user',
          filterable: false,
          sortable: false
        },
        {
          text: 'Name',
          value: 'name',
          name: 'name',
          filterable: true,
          sortable: false
        },
        {text: 'Email', value: 'email', name: 'email', filterable: true, sortable: false},
        {
          text: 'Verified',
          value: 'is_verified',
          name: 'is_verified',
          filterable: false,
          sortable: false
        },
        {text: 'Gender', value: 'gender', name: 'gender', filterable: false, sortable: false},
        {
          text: 'Last Login',
          value: 'last_login',
          name: 'last_login',
          filterable: false,
          sortable: false
        },
        {
          text: "Created",
          value: "created_at",
          name: "created_at",
          filterable: false,
          sortable: true
        },
        {text: "Updated", value: "updated_at", name: "updated_at", filterable: false, sortable: false},
        {text: 'Action', value: 'actions', data: null, name: null, filterable: false, sortable: false},
      ],
      validationRules: {
        valid: true,
        typeOfUserRules: [
          v => !!v || "This field is required"
        ],
        genderRules: [
          v => !!v || "This field is required"
        ],
        sourceRules: [
          v => !!v || "This field is required"
        ],
        emailRules: [
          v => !!v || "This field  is required",
          v => (v && v.length <= 100) || "Must be less than 100 characters",
          v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ],
        passwordRules: [
          v => !!v || "Password is required",
          v => (v && v.length <= 20) || "Password must be less than 20 characters",
          v => (v && v.length >= 6) || "Password must be at least 6 characters"
        ],
        permissionRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select Permission(s)';
            else return true;
          }
        ]
      },
      items: [],
      nameLabel: '',
      userTypeIsInd: false,
      permissionsFilter: [],
      permissionsIdsFilter: [],
      sourcesFilter: [],
      salutationFilter: [],
      tlnAuthorityFilter: [],
      isTlnAuthorityLoaded: false,
      ornAuthorityFilter: [],
      isOrnAuthorityLoaded: false,
      brnAuthorityFilter: [],
      isBrnAuthorityLoaded: false,
      drnAuthorityFilter: [],
      isDrnAuthorityLoaded: false,
      dialog: false,
      show: false,
      editedItem: {},
      typeOfUser: [
        {value: "0", title: 'Individual'},
        {value: "1", title: 'Institution/Company/Organization'}
      ],
      genders: [
        {value: "0", title: 'Male'},
        {value: "1", title: 'Female'}
      ],
      searchFormData: {
        typeOfUserSearch: '',
        fullNameSearch: '',
        emailSearch: ''
      },
      deleteDialog: false,
      itemToDelete: {},
      assigneeId: '',
      staffFilter: [],
    }
  },
  watch: {
    // params: {
    //   handler() {
    //     this.loadData().then(response => {
    //       this.items = response.items;
    //       this.totalRecords = response.total;
    //     });
    //   },
    //   deep: true
    // },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_USER_ERRORS);
      if (!val) this.editedItem = {};
      if (!val) this.showUploadedFiles = false;
      if (!val) this.nameLabel = '';
      if (!val) this.activeTab = 0;
      if (!val) this.userTypeIsInd = false;
      if (!val) this.$store.state.selections.selectedPermissionIds = []
      if (!val) bus.$emit("moduleFilesDeletedByModal");
      if (!val) bus.$emit("moduleLinksDeletedByModal");
      if (!val) bus.$emit("userDetailsDeletedByModal");
      if (!val) bus.$emit("userBankDetailsDeletedByModal");
      if (!val) bus.$emit("userCommentsDeletedByModal");
    },
    deleteDialog(val) {
      if (!val) {
        let that = this
        this.staffFilter = []
        setTimeout(function (){
          that.getStaff(false);
        }, 500)
      }
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].data +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "User's Panel", route: "users"},
      {title: "Users"}
    ]);
    if (this.$route.query.hasOwnProperty('profile') && this.$route.query.profile === '1') {
      this.showEditDialog(this.$store.state.auth.user)
    }
    this.refreshData(true);
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query += '&' + searchFormDataQuery
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 6 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(USERS_LIST, {q: query, columns: this.columns}).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    refreshData(loadDep = true) {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        if (this.$store.state.auth.user.type === 1) {
          if (loadDep) this.getAdminPermissions();
        } else {
          if (loadDep) this.getVendorPermissions();
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_USER_DETAILS, item.id).then((data) => {
        let tlnArr = [];
        let ornArr = [];
        let brnArr = [];
        let drnArr = [];
        this.editedItem = data.records || {};
        this.editedItem.is_edit = true;
        if (data.records.type_of_user === '0') {
          this.userTypeIsInd = true;
          this.nameLabel = 'Full Name'
        } else {
          this.userTypeIsInd = false;
          this.nameLabel = 'Company Name'
        }
        if (data.records.tln_authority) {
          tlnArr.push(data.records.tln_authority)
          this.tlnAuthorityFilter = tlnArr;
        }
        if (data.records.orn_authority) {
          ornArr.push(data.records.orn_authority)
          this.ornAuthorityFilter = ornArr;
        }
        if (data.records.brn_authority) {
          brnArr.push(data.records.brn_authority)
          this.brnAuthorityFilter = brnArr;
        }
        if (data.records.drn_authority) {
          drnArr.push(data.records.drn_authority)
          this.drnAuthorityFilter = drnArr;
        }
        this.$store.state.selections.selectedPermissionIds = this.editedItem.permission_ids;
        this.dialog = true;
        this.showUploadedFiles = true;
      });
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        let method = CREATE_USER;
        let id = item.id;
        this.$set(item, "image", this.$store.state.uploads.uploadedStateFiles);
        this.$set(item, "user_files", this.$store.state.uploads.uploadedStateOtherFiles);
        this.$set(item, "user_links", this.$store.state.uploads.uploadedStateLinks);
        this.$set(item, "user_details", this.$store.state.users.uploadedUserDetails);
        this.$set(item, "user_bank_details", this.$store.state.users.uploadedUserBankDetails);
        this.$set(item, "user_card_details", this.$store.state.users.uploadedUserCardDetails);
        this.$set(item, "user_comments", this.$store.state.users.uploadedUserComments);
        this.$set(item, "permission_ids", this.$store.state.selections.selectedPermissionIds);
        let data = {
          data: item
        };
        if (id) {
          // if the item has an id, we're updating an existing item
          method = UPDATE_USER;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_USER_ERRORS);
        // save the record
        this.$store.dispatch(method, {'slug': id, data: data.data}).then(() => {
          if (method === 'update_users') {
            this.showUploadedFiles = false;
            bus.$emit("moduleFilesDeletedByModal");
            bus.$emit("moduleLinksDeletedByModal");
            bus.$emit("userDetailsDeletedByModal");
            bus.$emit("userBankDetailsDeletedByModal");
            bus.$emit("userCommentsDeletedByModal");
            this.showEditDialog(item);
          } else {
            this.dialog = false;
          }
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          // this.editedItem.id = response.records.id
          // if (!id) {
          //   this.items.unshift(this.editedItem)
          // }
          // this.dialog = !this.dialog
          if (this.$route.query.hasOwnProperty('addModule')) {
            window.close();
          }
        })
      }
    },
    deleteItem(item, assigneeId) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to archive '" + item.name + "'?", clr: 'red', callback: function () {
          this.$store.dispatch(DELETE_USER, {
            'user_id': item.id,
            'assignee_id': assigneeId
          }).then(() => {
            that.deleteDialog = false
            that.itemToDelete = {}
            that.assigneeId = ''
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
    showDeleteDialog(item) {
      const index = this.staffFilter.findIndex(element => {
        if (element.id === item.id) {
          return true;
        }
      });
      if (index !== -1) this.staffFilter.splice(index, 1);
      this.itemToDelete = item
      this.deleteDialog = true
    },
    getAdminPermissions(loadNext = true) {
      this.permissionsIdsFilter = [];
      this.$store.dispatch(PERMISSIONS_ADMIN_LIST).then((data) => {
        let that = this;
        this.permissionsFilter = data.records.permissionsList;
        this.permissionsFilter.forEach(function (v) {
          if (v.permissions && v.permissions.length > 0) {
            v.permissions.forEach(function (vv) {
              that.permissionsIdsFilter.push(vv.id)
            })
          }
        })
        if (loadNext) this.getStaff();
      });
    },
    getVendorPermissions(loadNext = true) {
      this.$store.dispatch(PERMISSIONS_VENDOR_LIST).then((data) => {
        let that = this;
        this.permissionsFilter = data.records.permissionsList;
        this.permissionsFilter.forEach(function (v) {
          if (v.permissions && v.permissions.length > 0) {
            v.permissions.forEach(function (vv) {
              that.permissionsIdsFilter.push(vv.id)
            })
          }
        })
        if (loadNext) this.getStaff();
      });
    },
    getStaff(loadNext = true) {
      this.$store.dispatch(STAFF_LIST).then((data) => {
        this.staffFilter = data.records.staffList;
        if (loadNext) this.getSalutations()
      });
    },
    getSalutations(loadNext = true) {
      this.$store.dispatch(SALUTATIONS_SIMPLE_LIST).then((data) => {
        this.salutationFilter = data.records.salutationsList;
        if (loadNext) this.getSources();
      });
    },
    getSources() {
      this.$store.dispatch(SOURCES_SIMPLE_LIST).then((data) => {
        this.sourcesFilter = data.records.sourcesList;
      });
    },
    typeOfUserChange(v) {
      if (v && v === '0') {
        this.userTypeIsInd = true;
        this.nameLabel = 'Full Name'
      } else {
        this.userTypeIsInd = false;
        this.nameLabel = 'Company Name'
      }
    },
    tlnAuthorityFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isTlnAuthorityLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'All',
                q: e.target.value
              })
              .then(data => {
                that.tlnAuthorityFilter = data.records.contactsList;
                that.isTlnAuthorityLoaded = false;
              });
        }
      }, 900);
    },
    ornAuthorityFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isOrnAuthorityLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'All',
                q: e.target.value
              })
              .then(data => {
                that.ornAuthorityFilter = data.records.contactsList;
                that.isOrnAuthorityLoaded = false;
              });
        }
      }, 900);
    },
    brnAuthorityFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isBrnAuthorityLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'All',
                q: e.target.value
              })
              .then(data => {
                that.brnAuthorityFilter = data.records.contactsList;
                that.isBrnAuthorityLoaded = false;
              });
        }
      }, 900);
    },
    drnAuthorityFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isDrnAuthorityLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'All',
                q: e.target.value
              })
              .then(data => {
                that.drnAuthorityFilter = data.records.contactsList;
                that.isDrnAuthorityLoaded = false;
              });
        }
      }, 900);
    },
    hitAdvanceSearch(reset = false) {
      this.pagination.page = 1
      if (reset) {
        this.searchFormData = {
          typeOfUserSearch: '',
          fullNameSearch: '',
          emailSearch: ''
        };
      }
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    updateStatus(status, id, column) {
      this.$store.dispatch(CLEAR_USER_ERRORS);
      let data = {
        status: status,
        column: column
      };
      this.$store.dispatch(UPDATE_USER_STATUS, {'slug': id, data: data}).then(() => {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      });
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.users.errors
    }),
    pageCount() {
      // return this.totalRecords / this.pagination.rowsPerPage
      return this.params.itemsPerPage <= this.totalRecords ? Math.ceil(this.totalRecords / this.params.itemsPerPage) : 0
    }
  }
};
</script>