<template>
  <v-dialog persistent v-model="this.$store.state.selections.permissionsDialog" fullscreen>
    <v-card>
      <v-app-bar
          fixed
          dark
          color="#00004d"
      >
        <v-btn
            icon
            dark
            @click="$store.state.selections.permissionsDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span>Select Permissions</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn
              dark
              text
              @click="checkAll"
              v-if="permissionsFilter.length > 0"
          >
            Check All
          </v-btn>
          <v-btn
              dark
              text
              @click="uncheckAll"
              v-if="permissionsFilter.length > 0"
          >
            Uncheck All
          </v-btn>
          <v-btn
              dark
              text
              @click="$store.state.selections.permissionsDialog = false"
          >
            Save
          </v-btn>
        </v-toolbar-items>
        <v-menu
            bottom
            :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="hidden-md-and-up"
                height="25"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
            >
              Actions
            </v-btn>
          </template>

          <v-list>
            <v-list-item
                v-if="permissionsFilter.length > 0">
              <v-btn
                  text
                  @click="checkAll"
              >
                Check All
              </v-btn>
            </v-list-item>
            <v-list-item
                v-if="permissionsFilter.length > 0">
              <v-btn
                  text
                  @click="uncheckAll"
              >
                Uncheck All
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                  text
                  @click="$store.state.selections.permissionsDialog = false"
              >
                Save
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <v-card class="mt-15">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3" v-for="(role, key) in permissionsFilter" :key="key">
              <h4 class="mb-5">{{ role.display_name }}</h4>
              <v-checkbox
                  v-for="(permission, pkey) in role.permissions"
                  :key="pkey"
                  :label="permission.display_name"
                  color="#00004d"
                  v-model="$store.state.selections.selectedPermissionIds"
                  :value="permission.id"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['permissionsFilter', 'permissionsIdsFilter'],
  methods: {
    checkAll(){
      this.$store.state.selections.selectedPermissionIds = this.permissionsIdsFilter;
    },
    uncheckAll(){
      this.$store.state.selections.selectedPermissionIds = [];
    }
  }
};
</script>