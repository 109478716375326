<template>
  <v-col cols="12">
    <h4>{{ moduleTitle }}</h4>
    <div class="form-group" v-for="(comment,k) in comments" :key="k" style="margin: 0">
      <v-row>
        <v-col cols="12">
          <v-textarea
              outlined
              dense
              clearable
              label="Comment"
              color="primary"
              maxlength="500"
              :counter="500"
              @keyup="update"
              v-model="comment.comment">
            <template v-slot:append-outer>
              <v-btn
                  @click="remove(k)"
                  v-show="k || ( !k && comments.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1">
                <v-icon small dark>fas fa-minus-circle</v-icon>
              </v-btn>
              <v-btn
                  @click="add(k)"
                  v-show="k == comments.length-1"
                  small
                  color="success"
                  class="ma-0">
                <v-icon small dark>fas fa-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-textarea>
          <h6>{{comment.created_user_name ? 'Added By: ' + comment.created_user_name : ''}}</h6>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
  </v-col>
</template>

<script>
import {bus} from "@/main";

export default {
  name: 'user-comments',
  props: ['moduleOf', 'moduleTitle', 'userCommentsOld'],
  data() {
    return {
      comments: [
        {comment: ''}
      ]
    }
  },
  watch: {
    comments: {
      handler(v) {
        this.$store.state.users.uploadedUserComments = v;
      },
      deep: true
    },
    userCommentsOld: {
      handler() {
        if (this.userCommentsOld.length > 0) {
          this.comments = this.userCommentsOld
        } else {
          this.comments = [
            {comment: ''}
          ]
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.userCommentsOld.length > 0) {
      this.comments = this.userCommentsOld
    } else {
      this.comments = [
        {comment: ''}
      ]
    }
    bus.$on("userCommentsDeletedByModal", () => {
      this.comments = [
        {comment: ''}
      ]
      this.$store.state.users.uploadedUserComments = [];
    });
  },
  methods: {
    add() {
      this.comments.push({comment: ''});
    },
    update() {
    },
    remove(index) {
      this.comments.splice(index, 1);
    },
  }
}
</script>